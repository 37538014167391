@import '../../../../scss/theme-bootstrap';

.product-replenishment-select {
  position: relative;
  select {
    border-top-#{$rdirection}-radius: 0;
    border-bottom-#{$rdirection}-radius: 0;
  }
  &__select {
    width: 90%;
    float: $ldirection;
    @include breakpoint($med-small-up) {
      width: 95%;
    }
    @include breakpoint($landscape-up) {
      width: 90%;
    }
  }
  .replenishment__description--link {
    @include button2;
    border-#{$ldirection}: none;
    line-height: get-line-height(15px, 25px);
    min-width: 0;
    padding: 3px 0 0;
    position: absolute;
    #{$rdirection}: 0;
    text-align: center;
    width: 10%;
    @include breakpoint($med-small-up) {
      width: 5%;
    }
    @include breakpoint($landscape-up) {
      width: 10%;
    }
    .device-mobile & {
      line-height: get-line-height(15px, 30px);
    }
  }
  .replenishment__discount-text {
    display: inline-block;
    margin: 10px 0;
    @include breakpoint($landscape-up) {
      margin: 0;
    }
  }
}
